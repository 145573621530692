import imageMiddleF from '../assets/img/zelda_banner.jpg';
import imageMiddleS from '../assets/img/sekiro_banner.jpg';

const DataInfo = [
    {
        key: 0,
        image: imageMiddleF,
        text: 'The Legend of Zelda - Breath of th wild'
    },
    {
        key: 1,
        image: imageMiddleS,
        text: 'SEKIRO - Shadows die twice'
    }
]

export default DataInfo;